import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/cohort_progress',
        name: 'cohort_progress',
        component: () => import('../views/CohortProgressView.vue')
    },
    {
        path: '/curriculum',
        name: 'curriculum',
        component: () => import('../views/CurriculumView.vue')
    },
    {
        path: '/cohort-curriculum',
        name: 'cohort-curriculum',
        component: () => import('../views/CohortCurriculumView.vue')
    },
    {
        path: '/cohort-members',
        name: 'cohort-members',
        component: () => import('../views/CohortMembersView.vue')
    },
    {
        path: '/manage-mentees',
        name: 'manage-mentees',
        component: () => import('../views/ManageMenteesView.vue')
    },
    {
        path: '/manage-mentors',
        name: 'manage-mentors',
        component: () => import('../views/ManageMentorsView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
