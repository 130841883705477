/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4929fc73-9b54-4a3e-80b9-4e8fbee81651",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1qdlSlf5t",
    "aws_user_pools_web_client_id": "59dfph8qenalo81cmin1n9fhf1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://t43f7vxie5dzpnpb5e7uyazqim.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "reskilltracker-20220610130256-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d26tb1d81hb7mo.cloudfront.net",
    "aws_mobile_analytics_app_id": "e85cbe9ea8ed4112896195f1c59263f9",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "e85cbe9ea8ed4112896195f1c59263f9",
            "region": "us-east-1"
        }
    }
};


export default awsmobile;
