import { createApp } from 'vue';
import App from './App.vue';
import AmplifyVue from '@aws-amplify/ui-vue';
import { Amplify, Analytics } from 'aws-amplify';
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import veProgress from "vue-ellipse-progress";


import awsExports from './aws-exports';
Amplify.configure(awsExports);

const app = createApp(App);
app.use(AmplifyVue);
app.use(router);
app.use(veProgress);
app.mount('#app');
Analytics.record({ name: "AppLoaded" });
