<template>
  <div class="row">
    <div class="col col-sm-1"></div>
    <div class="col-5">
      <h1 v-if="mentee.firstName">
        {{ mentee.firstName }} {{ mentee.lastName }}'s Curriculum
      </h1>
      <h6 v-if="mentor.name">Mentor: {{ mentor.name }}</h6>
    </div>
    <div class="col-1">
      <br />
      <ve-progress :progress="progressPercent" :size="100">
        <span> {{ progress }} / {{ total }}</span>
      </ve-progress>
    </div>
    <div
      class="col-4 d-flex justify-content-end"
      v-if="adminUser || mentorUser"
    >
      <p>
        Administrative Load (read only)
        <br />
        <input type="text" v-model="mentee.alias" placeholder="Mentee Alias" />
        <button v-on:click="loadMentee">Load Mentee</button>
      </p>
    </div>
    <div class="col col-sm-1"></div>
  </div>
  <div class="row gx-5 p-3" v-if="groupAdded">
    <div class="col col-sm-1"></div>
    <div class="col col-lg-10 border">
      <curricula-component
        :phase="this.phase_0_tasks"
        :name="'Prerequisites'"
        :mentee="this.mentee"
        :menteeUser="this.menteeUser"
      />
    </div>
    <div class="col col-sm-1"></div>
  </div>
  <div class="row gx-5 p-3" v-if="groupAdded && prerequisitesComplete">
    <div class="col col-sm-1"></div>
    <div class="col col-lg-10 border">
      <curricula-component
        :phase="this.phase_1_tasks"
        :name="'Phase 1'"
        :mentee="this.mentee"
        :menteeUser="this.menteeUser"
      />
    </div>
    <div class="col col-sm-1"></div>
  </div>
  <div class="row gx-5 p-3" v-if="groupAdded && prerequisitesComplete">
    <div class="col col-sm-1"></div>
    <div class="col col-lg-10 border">
      <curricula-component
        :phase="this.phase_2_tasks"
        :name="'Phase 2'"
        :mentee="this.mentee"
        :menteeUser="this.menteeUser"
      />
    </div>
    <div class="col col-sm-1"></div>
  </div>

  <div class="row gx-5 p-3" v-if="groupAdded && prerequisitesComplete">
    <div class="col col-sm-1"></div>
    <div class="col col-lg-10 border">
      <curricula-component
        :phase="this.phase_3_tasks"
        :name="'Phase 3'"
        :mentee="this.mentee"
        :menteeUser="this.menteeUser"
      />
    </div>
    <div class="col col-sm-1"></div>
  </div>

  <div class="row gx-5 p-3" v-if="groupAdded && prerequisitesComplete">
    <div class="col col-sm-1"></div>
    <div class="col col-lg-10 border">
      <curricula-component
        :phase="this.phase_4_tasks"
        :name="'Phase 4'"
        :mentee="this.mentee"
        :menteeUser="this.menteeUser"
      />
    </div>
    <div class="col col-sm-1"></div>
  </div>
</template>

<script>
import { API, Analytics } from "aws-amplify";
import {
  load_curricula_for_mentee,
  mentorByAlias,
  menteeByAlias,
} from "../queries/tracker_queries";
// ToDo: subscriptions for create and update curriculum progress
// TODO: refactor the rendering of a curriculum to a component
import CurriculaComponent from "@/components/CurriculaComponent.vue";
import { Auth } from "aws-amplify";

export default {
  components: {
    CurriculaComponent,
  },
  data() {
    return {
      mentee: { firstName: "", alias: "" },
      mentor: {},
      curriculumProgress: {},
      cohort: { curriculum: [] },
      phase_0_tasks: [],
      phase_1_tasks: [],
      phase_2_tasks: [],
      phase_3_tasks: [],
      phase_4_tasks: [],
      adminUser: false,
      menteeUser: false,
      mentorUser: false,
      groupAdded: false,
      progressPercent: 0,
      progress: 0,
      total: 0,
      prerequisitesComplete: false,
    };
  },
  async created() {
    let user = await Auth.currentAuthenticatedUser();
    if ("cognito:groups" in user.signInUserSession.accessToken.payload) {
      this.adminUser =
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "admins"
        );
      this.menteeUser =
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "mentees"
        );
      this.mentorUser =
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "mentors"
        );
      this.groupAdded =
        "cognito:groups" in user.signInUserSession.accessToken.payload;
    }
    if (this.menteeUser) {
      this.mentee.alias = user.username;
      this.loadMentee();
      console.log(this.mentee);
      console.log(this.mentor);
    } else if (this.mentorUser) {
      this.loadMentor(user.username);
      console.log(this.mentee);
      console.log(this.mentor);
    }
    Analytics.record({ name: "HomeVisit" });
  },
  methods: {
    updatePrerequisitesCompleted() {
      this.prerequisitesComplete = this.phase_0_tasks.every(this.isComplete);
    },
    isComplete(task) {
      return (
        task.menteeProgress.items.length > 0 &&
        task.menteeProgress.items[0].status === "Complete"
      );
    },
    async loadMentee() {
      this.mentor = {};
      const mentee = await API.graphql({
        query: menteeByAlias,
        variables: { alias: this.mentee.alias },
      });
      this.mentee = mentee.data.menteeByAlias.items[0];
      if (this.mentee.mentor.items.length > 0) {
        this.mentor = this.mentee.mentor.items[0];
      }
      this.loadCohort();
    },
    async loadMentor(alias) {
      const mentor = await API.graphql({
        query: mentorByAlias,
        variables: { alias: alias },
      });
      console.log(mentor);
      this.mentor = mentor.data.mentorByAlias.items[0];
      this.mentee = this.mentor.mentee;
      this.loadCohort();
    },
    async loadCohort() {
      const cohort = await API.graphql({
        query: load_curricula_for_mentee,
        variables: {
          cohortId: this.mentee.cohortMenteesId,
          menteeId: this.mentee.id,
        },
      });
      this.cohort = cohort.data.getCohort;
      console.log(this.cohort);
      this.phase_0_tasks = this.cohort.curriculum.items
        .filter((i) => i.phase === "Phase_0")
        .sort((a, b) => this.sortTasks(a, b));
      this.updatePrerequisitesCompleted();
      this.phase_1_tasks = this.cohort.curriculum.items
        .filter((i) => i.phase === "Phase_1")
        .sort((a, b) => this.sortTasks(a, b));
      this.phase_2_tasks = this.cohort.curriculum.items
        .filter((i) => i.phase === "Phase_2")
        .sort((a, b) => this.sortTasks(a, b));
      this.phase_3_tasks = this.cohort.curriculum.items
        .filter((i) => i.phase === "Phase_3")
        .sort((a, b) => this.sortTasks(a, b));
      this.phase_4_tasks = this.cohort.curriculum.items
        .filter((i) => i.phase === "Phase_4")
        .sort((a, b) => this.sortTasks(a, b));
      this.total = this.cohort.curriculum.items.length;

      // TODO move this to a function so it's dynamic at top of page
      this.progress = this.cohort.curriculum.items.filter(
        (i) =>
          i.menteeProgress.items.length > 0 &&
          i.menteeProgress.items[0].status === "Complete"
      ).length;
      this.progressPercent = (this.progress * 100) / this.total;
    },
    sortTasks(a, b) {
      return a["order"] - b["order"];
    },
  },
};
</script>

