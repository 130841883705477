<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
</script>
<template>
  <nav class="top navbar navbar-expand-lg navbar-dark bg-dark">
    <span class="navbar-brand mb-0 h1">re:Skill Learn</span>
    <div class="collapse navbar-collapse" id="navbarColor01"></div>
    <a
      class="nav-link"
      href="https://w.amazon.com/bin/view/AWS/WWCSTECH/WWCSWorkforce360/UntappedTalentProgram/NAMERre:Skill"
      >re:Skill Program Information
      <svg
        color="white"
        display="block"
        xmlns="http://www.w3.org/2000/svg"
        class="question"
        width="20"
        height="20"
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 20 20"
        data-test-component="StencilIconQuestionCircle"
        data-test-flagged="icon-accessibility"
        style="width: 20px; height: 20px"
      >
        <path
          fill-rule="evenodd"
          fill="currentColor"
          d="M10,1 C14.963,1 19,5.037 19,10 C19,14.963 14.963,19 10,19 C5.037,19 1,14.963 1,10 C1,5.037 5.037,1 10,1 Z M10,17 C13.859,17 17,13.859 17,10 C17,6.141 13.859,3 10,3 C6.141,3 3,6.141 3,10 C3,13.859 6.141,17 10,17 Z M9.792,15.4406228 C9.392,15.4346228 9.068,15.3046228 8.822,15.0506228 C8.576,14.7976228 8.456,14.4706228 8.461,14.0706228 C8.467,13.6706228 8.597,13.3476228 8.851,13.1006228 C9.104,12.8546228 9.431,12.7346228 9.831,12.7406228 C10.231,12.7466228 10.554,12.8766228 10.801,13.1286228 C11.047,13.3826228 11.167,13.7106228 11.162,14.1096228 C11.156,14.5096228 11.026,14.8336228 10.772,15.0796228 C10.519,15.3266228 10.192,15.4466228 9.792,15.4406228 Z M11.153,7.47862281 C11.158,7.11962281 11.05,6.83962281 10.828,6.64162281 C10.605,6.44362281 10.285,6.34062281 9.864,6.33562281 C9.165,6.32562281 8.376,6.52362281 7.5,6.93062281 L7.524,5.26562281 C8.391,4.80862281 9.314,4.58662281 10.295,4.60062281 C11.245,4.61462281 11.983,4.86062281 12.512,5.33762281 C13.04,5.81662281 13.297,6.46462281 13.286,7.28562281 C13.275,7.97462281 13.067,8.57262281 12.659,9.07662281 C12.252,9.57962281 11.595,10.0606228 10.689,10.5166228 L10.67,11.7926228 L8.96,11.7676228 L8.81,9.73962281 L9.596,9.33162281 C10.15,9.04962281 10.547,8.76862281 10.786,8.48662281 C11.025,8.20562281 11.147,7.86962281 11.153,7.47862281 Z"
        ></path></svg
    ></a>
    <authenticator>
      <template v-slot="{ signOut }">
        <div class="navbar-text">
          {{ username }}
          <br />
          <a href="#" @click="signOut">Sign Out</a>
        </div>
      </template>
    </authenticator>
  </nav>
</template>

<script>
export default {
  name: "BannerComponent",
  props: {
    username: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.question {
  display: inline;
}

a.nav-link {
  color: white;
}

a.nav-link:visited {
  color: white;
}

a.nav-link:hover {
  color: gray;
  font-weight: 400;
}

a.nav-link:active {
  color: white;
}
</style>
