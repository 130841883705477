<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
</script>

<template>
  <div class="container-fluid px-0">
    <authenticator>
      <template v-slot="{ user }">
        <banner-component :username="user.username" />
        <nav class="pages navbar navbar-expand-lg">
          <router-link to="/" class="nav-link">Home</router-link>
          <router-link
            to="/cohort_progress"
            class="nav-link"
            :class="{ active: $route.name === 'cohort_progress' }"
            v-if="isInGroups(user, ['admins', 'mentors'])"
            >Cohort Progress</router-link
          >
          <router-link
            to="/curriculum"
            class="nav-link"
            v-if="isInGroups(user, ['admins'])"
            >Curriculum</router-link
          >
          <router-link
            to="/cohort-curriculum"
            class="nav-link"
            v-if="isInGroups(user, ['admins'])"
            >Bulk Load Curriculum</router-link
          >
          <router-link
            to="/cohort-members"
            class="nav-link"
            v-if="isInGroups(user, ['admins'])"
            >Bulk Load Members</router-link
          >
          <router-link
            to="/manage-mentees"
            class="nav-link"
            v-if="isInGroups(user, ['admins'])"
            >Manage Mentees</router-link
          >
          <router-link
            to="/manage-mentors"
            class="nav-link"
            v-if="isInGroups(user, ['admins'])"
            >Manage Mentors</router-link
          >
        </nav>
        <router-view />
      </template>
    </authenticator>
  </div>
</template>

<style>
nav {
  padding-left: 25px;
  padding-right: 25px;
}

.pages {
  background-color: rgb(206, 223, 240);
  height: 5rem;
}

a.nav-link {
  color: black;
}

a.nav-link:visited {
  color: black;
}

a.nav-link:hover {
  color: gray;
  font-weight: bold;
}

a.nav-link:active {
  color: black;
  font-weight: bold;
}

a.router-link-active {
  color: black;
  font-weight: bold;
}
</style>

<script>
import BannerComponent from "@/components/BannerComponent.vue";

export default {
  // ToDo:
  // add a cognito pre-sign up lambda that only allows
  // 1) lowercase alpha characters for username (long term assert its in the phonetool/PAPI)
  // 2) email address that is part of the amazon domain
  // add a page to edit the curriculum
  // add a page to edit the mentees and mentors one at a time
  // Next enable mentee / mentor updating, including putting in to groups. If this requires admin API move to groups manually for now

  components: {
    BannerComponent,
  },
  methods: {
    isInGroups(user, groups) {
      let matched = false;
      if ("cognito:groups" in user.signInUserSession.accessToken.payload) {
        const userGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        groups.forEach((group) => {
          if (userGroups.includes(group)) {
            matched = true;
          }
        });
      }
      return matched;
    },
  },
};
</script>

