<template>
  <div class="row gy-5">
    <div class="col-10">
      <h2>{{ name }}<br /><br /></h2>
    </div>
  </div>
  <div v-for="c in this.phase" v-bind:key="c.id">
    <div class="row border text-start gy-3 p-1 bg-light">
      <div class="col-8">
        <h4>{{ c.task }}</h4>
        <p><b>Objective:</b> {{ c.objective }}</p>

        <p>
          <span style="text-decoration: italic">Using {{ c.platform }}</span
          >:
          <a
            v-if="c.link.items.length > 0"
            :href="c.link.items[0].target"
            target="_blank"
            >{{ c.link.items[0].name }}</a
          >
          <br />{{ c.deliverable }}
        </p>
      </div>
      <div class="col-4">
        <p class="d-flex justify-content-end">
          {{ c.milestone.replace(/_/g, " ") }}: {{ formatDuration(c.duration) }}
        </p>
        <p
          class="d-flex justify-content-end"
          v-if="requiresProof(c) && !isComplete(c)"
        >
          {{ c.validation.replace("Copy and paste", "") }}:&nbsp;
          <input
            type="text"
            v-model="c.menteeProgress.items[0].validation_link"
            placeholder="Valid URL required"
            id="validation{{ c.task }}"
          />
        </p>
        <p
          class="d-flex justify-content-end"
          v-if="requiresProof(c) && isComplete(c)"
        >
          <a v-bind:href="c.menteeProgress.items[0].validation_link">{{
            c.validation.replace("Copy and paste", "")
          }}</a>
        </p>
        <p class="d-flex justify-content-end">
          <button
            type="button"
            :class="buttonStyle(c)"
            v-on:click="updateProgress(c)"
          >
            {{ buttonText(c) }}
          </button>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-1">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { API, Analytics } from "aws-amplify";
import {
  complete_curriculum_progress,
  update_curriculum,
} from "../queries/tracker_queries";

export default {
  name: "CurriculaComponent",
  props: {
    name: String,
    phase: Object,
    mentee: Object,
    menteeUser: Boolean,
  },
  methods: {
    requiresProof(curriculum) {
      if (curriculum.enter_link === "Yes") {
        if (curriculum.menteeProgress.items.length === 0) {
          curriculum.menteeProgress.items[0] = {
            id: "",
            validation_link: null,
            status: "Incomplete",
          };
        }
        return true;
      }
    },
    async updateProgress(curriculum) {
      if (this.menteeUser) {
        let progress = {};
        if (this.requiresProof(curriculum)) {
          if (
            curriculum.menteeProgress.items[0].validation_link === null ||
            !/^(ftp|http|https):\/\/[^ "]+$/.test(
              curriculum.menteeProgress.items[0].validation_link
            )
          ) {
            return;
          }
        }

        if (curriculum.menteeProgress.items.length === 0) {
          progress = await API.graphql({
            query: complete_curriculum_progress,
            variables: {
              menteeId: this.mentee.id,
              curriculumId: curriculum.id,
            },
          });
          curriculum.menteeProgress.items[0] =
            progress.data.createMenteeCurriculumProgress;
          Analytics.record({ name: "ProgressUpdate" });
        } else if (curriculum.menteeProgress.items[0].id === "") {
          progress = await API.graphql({
            query: complete_curriculum_progress,
            variables: {
              menteeId: this.mentee.id,
              curriculumId: curriculum.id,
              validation_link:
                curriculum.menteeProgress.items[0].validation_link,
            },
          });
          curriculum.menteeProgress.items[0] =
            progress.data.createMenteeCurriculumProgress;
          Analytics.record({ name: "ProgressUpdate" });
        } else {
          const currentProgress = curriculum.menteeProgress.items[0];
          const status =
            currentProgress.status === "Incomplete" ? "Complete" : "Incomplete";
          progress = await API.graphql({
            query: update_curriculum,
            variables: {
              id: curriculum.menteeProgress.items[0].id,
              status: status,
              validation_link:
                curriculum.menteeProgress.items[0].validation_link,
            },
          });
          curriculum.menteeProgress.items[0] =
            progress.data.updateMenteeCurriculumProgress;
          Analytics.record({ name: "ProgressUpdate" });
        }
        this.$parent.updatePrerequisitesCompleted();
      }
    },
    isComplete(curriculum) {
      return this.buttonText(curriculum) === "Complete";
    },
    buttonText(curriculum) {
      return curriculum.menteeProgress.items.length === 0
        ? "Incomplete"
        : curriculum.menteeProgress.items[0].status;
    },
    buttonStyle(curriculum) {
      const button_style = {
        Incomplete: "btn btn-outline-secondary",
        Complete: "btn btn-outline-success",
      };
      return curriculum.menteeProgress.items.length === 0
        ? button_style["Incomplete"]
        : button_style[curriculum.menteeProgress.items[0].status];
    },
    formatDuration(duration) {
      const chunks = duration.split(":");
      const hours = Number(chunks[0]);
      const minutes = Number(chunks[1]);

      let display = "";
      if (hours > 0) {
        display += hours + " hours ";
      }
      if (minutes > 0) {
        display += minutes + " minutes ";
      }

      return display;
    },
  },
};
</script>